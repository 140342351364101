<template>
    <div>
        <CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filterSelectedValues" :filters-storage-key="filterSelectedValuesStorageKeyPrefix + filterSelectedValuesStorageKey" v-bind:reset-local-storage-filters-handler="resetLocalStorageListFilterValuesHandler" :table-fields="tableFields" :search-filters="filters" :buttons="buttons" :items-func="navigateToSvForm" ref="table" v-if="endpoint"></CustomDataTable>

        <v-dialog v-model="createForm.viewModal" width="500">
            <v-card>
                <v-card-title class="grey lighten-2">
                    New Service Form: {{ isIncentiveForm? 'Cash Incentive': 'Certificate of Origin' }}
                </v-card-title>

                <v-card-text class="pt-2">
                    <div v-if="isIncentiveForm">Are you sure that you want to create a new {{ isIncentiveForm? 'Cash Incentive': 'CO' }} Form?</div>
                    <div v-if="isCOForm" class="font-italic text-center pt-10 pb-10">Service unavailable!</div>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn type="button" small :disabled="loading" @click="createForm.viewModal = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn type="button" class="success" :disabled="loading" @click="createNewSvForm()" v-if="isIncentiveForm">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import Vue from 'vue';
import CustomDataTable from "../../../components/table/CustomDataTable";

import dayjs from "dayjs";

export default {
    name: "SvForms_view",
    //components: {DataRowModal, CustomDataTable},
    components: {
        CustomDataTable,
    },
    data: ()=>({
        loading: false,
        tableTitle: 'Service Forms List',
        endpoint: null,
        routeParams: null,
        filterSelectedValues:{},
        filterSelectedValuesStorageKeyPrefix: null,
        filterSelectedValuesStorageKey: null,
        localStorageFilters: null,
        tableFields: [],
        filters: [],

        buttons:[],
        createForm:{
            viewModal: false,
        },

        //userId: null,
    }),
    computed:{
        isIncentiveForm: function (){
            return this.routeParams.type ===  'incentive';
        },
        isCOForm: function (){
            return this.routeParams.type ===  'co';
        },
        isStatusTypeCompleted: function (){
            return this.routeParams.statusType ===  'completed';
        },
        isUserExporter: function(){
            return this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter;
        },
        isStatusTypePending: function(){
            return this.routeParams.statusType ===  'pending';
        },
        isStatusTypeApproved: function(){
            return this.routeParams.statusType ===  'approved';
        },
        filterCountries: function (){
            return this.$globalSettings.countries.map(country => ({
                id: country.id,
                label: country.name
            }));
        },
    },
    methods:{
        loadLocalStorageFilters: async function (key) {
            let localStorageFilters = await this.$globalHelpers.getLocalStorageItem(this.filterSelectedValuesStorageKeyPrefix + key, null);
            if (localStorageFilters) {
                this.localStorageFilters = JSON.parse(localStorageFilters);
            }
        },
        resetLocalStorageListFilterValuesHandler: async function () {
            await this.initialize(true);
        },
        initialize: async function (loadList) {
            //console.log(this.routeParams);
            this.filterSelectedValues = {
                FormTypeId: 0,

                ExporterTypeIds: [],
                ExporterTypeIdsExcludes: [],
                StatusIds: [],
                StatusIdExcludes: [],
                ServiceOfficeIds: [],
                ServiceOfficeIdsExcludes: [],
                IncentiveTypeIds: [],
                IncentiveTypeIdsExcludes: [],
                CountryIds: [],
                CountryIdsExcludes: [],

                UserId: null,
                IncentiveFormId: null,
                CoFormId: null,
                CoFormNo: null,

                OrganizationName: null,
                Username: null,
                Skip: 0,
                Take: 10,
            };
            this.filterSelectedValuesStorageKey = null;
            this.localStorageFilters = null;
            this.tableFields = [];
            this.filters = [];

            this.createForm = {
                viewModal: false,
                validTillDate: null,
                formNo: null,
            };

            this.filterSelectedValuesStorageKeyPrefix = this.$globalHelpers.getAuthStoreValue('user_id');
            await this.prepareFilters();
            this.prepareFields();
            this.prepareSearchFilters();

            if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter) {
                this.filterSelectedValues.UserId = this.$globalHelpers.getAuthStoreValue('user_id');
            }

            console.log(this.$route.params.type);
            if(this.$route.params.type === 'incentive') {
                this.endpoint = this.$globalSettings.api.endpoints.svForm.incentiveList;
            }else{
                this.endpoint = this.$globalSettings.api.endpoints.svForm.coList;
            }

            if (loadList) {
                await this.$refs.table.loadList();
            }

            await this.checkEAPermission();
        },
        filterStatusIDs: function (statusIDs) {
            console.log(statusIDs);
            if(!Array.isArray(statusIDs)){
                return [];
            }
            if(this.isStatusTypeCompleted){
                return statusIDs.filter( x => {
                    return x === this.$globalEnums.SvFormIncentiveStatuses.Invalid
                        || x === this.$globalEnums.SvFormIncentiveStatuses.Approved
                        || x === this.$globalEnums.SvFormIncentiveStatuses.Cancelled;
                });
            }else{
                return statusIDs.filter( x => {
                    return x !== this.$globalEnums.SvFormIncentiveStatuses.Invalid
                        && x !== this.$globalEnums.SvFormIncentiveStatuses.Approved
                        && x !== this.$globalEnums.SvFormIncentiveStatuses.Cancelled;
                });
            }
        },
        filterStatusExcludeIDs: function (statusIDs) {
            if(this.isStatusTypeCompleted){
                return statusIDs.filter( x => {
                    return x !== this.$globalEnums.SvFormIncentiveStatuses.Invalid
                        && x !== this.$globalEnums.SvFormIncentiveStatuses.Approved
                        && x !== this.$globalEnums.SvFormIncentiveStatuses.Cancelled;
                });
            }else{
                return statusIDs.filter( x => {
                    return x === this.$globalEnums.SvFormIncentiveStatuses.Invalid
                        || x === this.$globalEnums.SvFormIncentiveStatuses.Approved
                        || x === this.$globalEnums.SvFormIncentiveStatuses.Cancelled;
                });
            }
        },
        prepareFilters: async function () {
            //let params = this.routeParams;

            this.filterSelectedValues.FormTypeId = this.isIncentiveForm? this.$globalEnums.SvFormTypes.Incentive: this.$globalEnums.SvFormTypes.CO;

            this.filterSelectedValuesStorageKey = 'svFormsList_' + (this.isIncentiveForm? 'IN': 'CO') + '_' + this.routeParams.statusType;
            this.filterSelectedValues.ExporterType = this.$globalHelpers.getAuthStoreValue('user_exporter_type');

            await this.loadLocalStorageFilters(this.filterSelectedValuesStorageKey);
            //console.log(this.filterSelectedValuesStorageKey);
            //console.log(this.localStorageFilters);

            //if filter selections are previously saved in the local storage
            if (this.localStorageFilters) {
                //**ExporterTypeIds**
                if (this.localStorageFilters.ExporterTypeIds && this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter) {
                    if(this.localStorageFilters.ExporterTypeIds.length > 0) {
                        this.filterSelectedValues.ExporterTypeIds = this.localStorageFilters.ExporterTypeIds;
                    }else {
                        this.filterSelectedValues.ExporterTypeIds = [
                            this.$globalEnums.ExporterTypes.None,
                            this.$globalEnums.ExporterTypes.Textile,
                            this.$globalEnums.ExporterTypes.NonTextile,
                        ];
                    }
                }else{
                    this.filterSelectedValues.ExporterTypeIds.push(this.$globalHelpers.getAuthStoreValue('user_exporter_type'));
                }
                //**StatusIds**
                this.filterSelectedValues.StatusIds = this.localStorageFilters.StatusIds.length > 0? this.filterStatusIDs(this.localStorageFilters.StatusIds): this.filterStatusIDs(this.$globalHelpers.enumToArrayOfValue(this.$globalEnums.SvFormIncentiveStatuses));
                this.filterSelectedValues.StatusIdExcludes = this.filterStatusExcludeIDs(this.$globalHelpers.enumToArrayOfValue(this.$globalEnums.SvFormIncentiveStatuses));

                //**ServiceOfficeIds**
                this.filterSelectedValues.ServiceOfficeIds = this.localStorageFilters.ServiceOfficeIds;
                this.filterSelectedValues.ServiceOfficeIdsExcludes = this.localStorageFilters.ServiceOfficeIdsExcludes;

                //**IncentiveTypeIds**
                this.filterSelectedValues.IncentiveTypeIds = this.localStorageFilters.IncentiveTypeIds;
                this.filterSelectedValues.IncentiveTypeIdsExcludes = this.localStorageFilters.IncentiveTypeIdsExcludes;

                //**CountryIds**
                this.filterSelectedValues.CountryIds = this.localStorageFilters.CountryIds;
                this.filterSelectedValues.CountryIdsExcludes = this.localStorageFilters.CountryIdsExcludes;
            }
            //if filter selections are not available in the local storage
            else {
                //**ExporterTypeIds**
                //Set department filter Textile/Non-Textile based on User ExporterType
                if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter){
                    this.filterSelectedValues.ExporterTypeIds.push(this.$globalHelpers.getAuthStoreValue('user_exporter_type'));
                }else{
                    this.filterSelectedValues.ExporterTypeIds = [
                        this.$globalEnums.ExporterTypes.None,
                        this.$globalEnums.ExporterTypes.Textile,
                        this.$globalEnums.ExporterTypes.NonTextile,
                    ];
                }

                //**StatusIds**
                //set available status filter options for Incentive form
                if(this.isIncentiveForm){
                    this.filterSelectedValues.StatusIds = this.filterStatusIDs(this.$globalHelpers.enumToArrayOfValue(this.$globalEnums.SvFormIncentiveStatuses));
                    this.filterSelectedValues.StatusIdExcludes = this.filterStatusExcludeIDs(this.$globalHelpers.enumToArrayOfValue(this.$globalEnums.SvFormIncentiveStatuses));
                }
                //set available status filter options for CO form
                if(this.isCOForm){
                    this.filterSelectedValues.StatusIds = this.filterStatusIDs(this.$globalHelpers.enumToArrayOfValue(this.$globalEnums.SvFormCOStatuses));
                }

                //**ServiceOfficeIds**
                //set available service office filter options for Exporters
                if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter) {
                    this.filterSelectedValues.ServiceOfficeIds.push(this.$globalHelpers.getAuthStoreValue('user_service_office_id'));
                    this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices).forEach((servOfc) => {
                        if(servOfc.id !== this.$globalHelpers.getAuthStoreValue('user_service_office_id')){
                            this.filterSelectedValues.ServiceOfficeIdsExcludes.push(servOfc.id);
                        }
                    });

                }
                //set available service office filter options for Epb Officers
                else {
                    this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices).forEach((servOfc) => {
                        if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DG
                            || this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Admin){
                            this.filterSelectedValues.ServiceOfficeIds.push(servOfc.id);
                        }else{
                            if(servOfc.id === this.$globalHelpers.getAuthStoreValue('user_service_office_id')){
                                this.filterSelectedValues.ServiceOfficeIds.push(servOfc.id);
                            }
                        }
                    });
                    this.filterSelectedValues.ServiceOfficeIdsExcludes = [];
                }

                //**IncentiveTypeIds**
                if(this.isIncentiveForm){
                    this.filterSelectedValues.IncentiveTypeIds = this.$globalSettings.svFormIncentiveTypes.map(type => type.id);
                }

                //**CountryIds**
                this.filterSelectedValues.CountryIds = this.$globalSettings.countries.map(type => type.id);
            }

        },
        prepareFields: function(){
            this.tableFields.push(
                {key: 'Id', label: 'ID', type: 'number', orderBy: true, prefix: null, suffix: null},
            );
            if(this.isIncentiveForm){
                if(this.isUserExporter){
                    this.tableFields.push({key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.SvFormIncentiveStatuses), excludes: this.filterSelectedValues.StatusIdExcludes, orderBy: true, prefix: null, suffix: null, replaceLabelsGroup: {label: 'Processing', ids: [this.$globalEnums.SvFormIncentiveStatuses.DA_Desk, this.$globalEnums.SvFormIncentiveStatuses.AD_desk, this.$globalEnums.SvFormIncentiveStatuses.Director_desk, this.$globalEnums.SvFormIncentiveStatuses.DD_Desk, this.$globalEnums.SvFormIncentiveStatuses.DG_desk, this.$globalEnums.SvFormIncentiveStatuses.VC_Desk]}});
                }else{
                    this.tableFields.push({key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.SvFormIncentiveStatuses), excludes: this.filterSelectedValues.StatusIdExcludes, orderBy: true, prefix: null, suffix: null});
                }
            }
            if(this.isCOForm){
                this.tableFields.push(
                    {key: 'CoFormNo', label: 'Form#', type: 'number', orderBy: true, prefix: 'P', suffix: null},
                );
                if(!this.isUserExporter){
                    this.tableFields.push(
                        {key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.SvFormCOStatuses), excludes: this.filterSelectedValues.StatusIdExcludes, orderBy: true, prefix: null, suffix: null},
                    );
                }
            }
            this.tableFields.push(
                {key: 'SvForm.DestinationCountryId', label: 'Country', type: 'array', array: this.filterCountries, excludes: [], orderBy: false, prefix: null, suffix: null},
                {key: 'SvForm.User.ExporterType', label: 'Dept.', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes), excludes: [], orderBy: false, prefix: null, suffix: null},
                {key: 'SvForm.ServiceOfficeId', label: 'Serv. Office', type: 'arrayFilter', filterKey: 'ServiceOfficeIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices), excludes: this.filterSelectedValues.ServiceOfficeIds, orderBy: true, prefix: null, suffix: null},
                {key: 'SvForm.User.OrganizationName', label: 'Organization', type: 'string', orderBy: false, prefix: null, suffix: null},
                {key: 'SvForm.User.Username', label: 'Reg. No.', type: 'string', orderBy: false, prefix: null, suffix: null},
            );
            if(this.isIncentiveForm){
                this.tableFields.push({key: 'Modified', label: 'Updated', type: 'date', orderBy: true, prefix: null, suffix: null});
            }
            if(this.isCOForm){
                this.tableFields.push({key: 'Modified', label: 'Updated', type: 'date', orderBy: true, prefix: null, suffix: null});
            }
        },
        prepareSearchFilters: function(){
            if(this.isIncentiveForm){
                this.filters.push(
                    {key: 'IncentiveFormId', label: 'ID', type: 'number', cols: 1},
                );
                if(!this.isUserExporter || (this.isUserExporter && this.isStatusTypeCompleted)){
                    this.filters.push(
                        {key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.SvFormIncentiveStatuses), excludes: this.filterSelectedValues.StatusIdExcludes},
                    );
                }
            }
            if(this.isCOForm){
                this.filters.push(
                    {key: 'COFormId', label: 'ID', type: 'number', cols: 1},
                    {key: 'CoFormNo', label: 'Form #', type: 'number', cols: 1},
                );
                if(!this.isUserExporter){
                    this.filters.push(
                        {key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.SvFormCOStatuses), excludes: this.filterSelectedValues.StatusIdExcludes},
                    );
                }
            }


            this.filters.push(
                {key: 'OrganizationName', label: 'Organization', type: 'string'},
                {key: 'Username', label: 'Reg. No.', type: 'string'},
                {key: 'EXPNo', label: 'EXP No', type: 'string', cols: 1},
                {key: 'ShippingBillNo', label: 'Shipping Bill No', type: 'string', cols: 1},
                {key: 'ServiceOfficeId', label: 'Service Office', type: 'arrayFilter', filterKey: 'ServiceOfficeIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices), excludes: this.filterSelectedValues.ServiceOfficeIdsExcludes},
            );
            if(this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter){
                this.filters.push({key: 'ExporterTypeId', label: 'Department', type: 'arrayFilter', filterKey: 'ExporterTypeIds', array: [{ id: this.$globalEnums.ExporterTypes.None, label: 'None' }, { id: this.$globalEnums.ExporterTypes.Textile, label: 'Textile' }, { id: this.$globalEnums.ExporterTypes.NonTextile, label: 'Non-Textile' } ], excludes: []});
            }
            this.filters.push({key: 'CountryId', label: 'Country', type: 'arrayFilter', filterKey: 'CountryIds', array: this.filterCountries, excludes: [2, 3], cols: 3},);
            if(this.isIncentiveForm){
                this.filters.push(
                    {key: 'IncentiveTypeId', label: 'Incentive Type', type: 'arrayFilter', filterKey: 'IncentiveTypeIds', array: this.$globalSettings.svFormIncentiveTypes, excludes: [], cols: 4},
                );
            }
        },

        //create form
        checkEAPermission: async function(){
            this.loading = true;
            this.buttons = [];
            this.createForm.viewModal = false;

            await this.$axios.get(this.$globalSettings.api.endpoints.ea.permissions, { params: { userId: this.$globalHelpers.getAuthStoreValue('user_id') }}).then(async (response) => {
                this.loading = false;

                if(response.data.ValidTillDate != null){
                    const validTillDate = dayjs(response.data.ValidTillDate).format("YYYY-MM-DD");
                    const dateToday = dayjs();

                    if (dateToday.isBefore(validTillDate) && !this.isStatusTypeCompleted) {
                        this.buttons.push({ label: 'New Service Form', icon: 'fa-solid fa-plus', type: 'nav', func: this.createFormDialog, funcParam: this.$globalEnums.ApplicationTypes.Renewal },);
                    } else {
                        this.buttons = [];
                        console.log("Current date is not less than ValidTillDate.");
                    }
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },


        createFormDialog: async function () {
            this.createForm.viewModal = true;
        },
        checkDuplicateForm: async function () {
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.svForm.checkDuplicateCoForm, {params: {formNo: this.createForm.formNo}}).then(async (response) => {
                this.loading = false;
                if(response.data.Status === 'success'){
                    await this.createNewSvForm();
                }else{
                    this.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        createNewSvForm: async function () {
            this.loading = true;
            let formData = {
                FormTypeId: this.isIncentiveForm? this.$globalEnums.SvFormTypes.Incentive: this.$globalEnums.SvFormTypes.CO,
                IncentiveFormLocalMaterials: [],
                IncentiveFormImportedMaterials: [],
                SvForm: {
                    SvFormHSCodes: []
                },
            };
            let endpoint = this.isIncentiveForm? this.$globalSettings.api.endpoints.svForm.saveIncentive: this.$globalSettings.api.endpoints.svForm.saveCO;
            await this.$axios.post(endpoint, formData).then(async (response) => {
                this.loading = false;
                this.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                this.createForm.viewModal = false;
                if(response.data.Status == 'success'){
                    await this.$refs.table.loadList();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        navigateToSvForm: async function (id) {
            await this.$router.push({name: 'App.SvForm', params: {type: this.routeParams.type, id: id}});
        },
    },
    created() {
        this.endpoint = null;
        this.routeParams = this.$route.params;
        this.initialize(false);

        this.$watch(
            () => this.$route.params,
            // eslint-disable-next-line no-unused-vars
            (toParams, previousParams) => {
                this.routeParams = toParams;
                this.initialize(true);
            }
        );
    }
}
</script>

<style scoped>

</style>