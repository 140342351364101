<template>
    <v-card :loading="loading" class="mx-auto">
        <template slot="progress">
            <v-progress-linear
                    :color="$globalSettings.progressbarClasses"
                    height="10"
                    indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title >
            <v-row>
                <v-col cols="12" sm="6">
                    <div class="secondary-text">{{tableTitle}}</div>
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <v-btn type="button" class="mr-3" v-for="(button, i) in buttons" :key="i" v-on:click="button.func(button.funcParam)"><v-icon class="mr-1" v-text="button.icon" v-if="button.icon" small></v-icon> {{button.label}}</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <!--<v-divider class="mx-4"></v-divider>-->

        <v-row no-gutters class="pl-5">
            <v-col cols="12" :sm="searchFilter.cols? searchFilter.cols: 2" class="mr-2" v-for="searchFilter in searchFilters" :key="searchFilter.key">

                <v-menu v-if="searchFilter.type === 'date'" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined v-model="filters[searchFilter.key]" :label="searchFilter.label" readonly v-bind="attrs" v-on="on" :disabled="loading" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="filters[searchFilter.key]"></v-date-picker>
                </v-menu>

                <v-select dense outlined v-else-if="searchFilter.type === 'arrayFilter'" :items="getFilteredArray(searchFilter.array, searchFilter.excludes)" item-value="id" item-text="label" :label="searchFilter.label" v-model="filters[searchFilter.filterKey]" multiple small-chips>
                    <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggleArrayFilterSelection(searchFilter)">
                            <v-list-item-action>
                                <v-icon :color="getFilteredArray(searchFilter.array, searchFilter.excludes).length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">(+{{ filters[searchFilter.filterKey].length - 1 }} others)</span>
                    </template>
                </v-select>

                <v-select dense outlined v-else-if="searchFilter.type === 'array'" :items="getFilteredArray(searchFilter.array, searchFilter.excludes)" item-value="id" item-text="label" :label="searchFilter.label" v-model="filters[searchFilter.key]"></v-select>

                <v-text-field dense outlined v-else :label="searchFilter.label" v-model="filters[searchFilter.key]" :disabled="loading" clearable v-on:keyup.enter="loadList"></v-text-field>
            </v-col>

            <v-col cols="12" sm="1" class="mr-2" v-if="searchFilters.length > 0">
                <v-btn-toggle dense>
                    <v-btn outlined color="secondary" dark :disabled="loading" v-on:click="loadList"><v-icon small>fas fa-magnifying-glass</v-icon></v-btn>
                    <v-btn outlined color="secondary" dark :disabled="loading" v-on:click="resetLocalStorageFilters"><v-icon small>fas fa-undo</v-icon></v-btn>
                </v-btn-toggle>

            </v-col>
            <v-col cols="12" sm="1" class="mr-2 pt-3" v-if="searchFilters.length > 0">

            </v-col>
        </v-row>

        <!--<v-divider class="mx-4"></v-divider>-->
        <v-card-text>
            <v-card outlined>
                <v-simple-table class="customDataTable">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left" v-for="tableField in tableFields" :key="tableField.key">
                                <div @click="changeOrderBy(tableField.key)" v-if="tableField.orderBy" class="accent-text font-weight-bold pointer-cursor">
                                    {{tableField.label}}
                                    <v-icon class="ml-2" x-small v-if="preparedOrderBy.descending && preparedOrderBy.key === tableField.key">fas fa-sort-up</v-icon>
                                    <v-icon class="ml-2" x-small v-else-if="!preparedOrderBy.descending && preparedOrderBy.key === tableField.key">fas fa-sort-down</v-icon>
                                    <v-icon class="ml-2" x-small v-else>fas fa-sort</v-icon>
                                </div>
                                <span v-else class="accent-text">{{tableField.label}}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="listItem in list" :key="listItem.Id" @click="itemsFunc(listItem.Id)" style="cursor: pointer;">
                            <td v-for="tableField in tableFields" :key="tableField.key">
                                <span v-if="tableField.type === 'date' && getColumnValue(listItem, tableField.key)">{{ $globalHelpers.getFormattedDate(getColumnValue(listItem, tableField.key), '', 'DD/MM/YYYY') }}</span>
                                <span v-else-if="tableField.type === 'array' || tableField.type === 'arrayFilter'">
                                    {{ prepareArrayFilterValue(listItem, tableField) }}
                                </span>
                                <span v-else-if="tableField.type === 'staticText'">
                                    {{ tableField.staticText }}
                                </span>
                                <span v-else><span v-if="getColumnValue(listItem, tableField.key)">{{ tableField.prefix }}{{ getColumnValue(listItem, tableField.key) }}{{ tableField.suffix }}</span></span>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-card-text>

        <v-row no-gutters class="pa-5 pl-7">
            <v-col sm="6">
                showing {{paginationFrom}} to {{paginationTo}} of {{listCount}}
            </v-col>
            <v-col sm="6" class="text-right">
                <v-btn outlined color="secondary" dark class="mr-1" :disabled="paginationPrevDisabled" v-on:click="paginatePrev"><v-icon>fas fa-chevron-left</v-icon></v-btn>
                <v-btn outlined color="secondary" dark :disabled="paginationNextDisabled" v-on:click="paginateNext"><v-icon>fas fa-chevron-right</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
//import Vue from 'vue';
export default {
    name: "CustomDataTable",
    data: () => ({
        loading: false,
        list: [],
        listCount: 0,

        preparedFilters:{},
        preparedOrderBy:{
            key: 'Modified',
            descending: true,
        },

        menu: false,
        modal: false,
        menu2: false,
    }),
    props:{
        endpoint:{
            type: String,
            default: ()=> '',
        },
        moduleType:{
            type: String,
            default: ()=> '',
        },
        tableTitle:{
            type: String,
            default: ()=> '',
        },
        filters:{
            type: Object,
            default: ()=> ({
                Skip: 0,
                Take: 10,
            })
        },
        filtersStorageKey:{
            type: String,
            default: ()=> null
        },
        tableFields: {
            type: Array,
            default: ()=> ([])
        },
        searchFilters: {
            type: Array,
            default: ()=> ([])
        },
        itemsFunc:{
            type: Function,
            default: ()=> null
        },
        buttons:{
            type: Array,
            default: ()=> ([])
        },
        resetLocalStorageFiltersHandler: {
            type: Function,
            default: function () {}
        },
    },
    computed:{
        /*filterInputs: function(){
            let inputs = [];
            this.searchFilters.forEach(function (field) {
                if(field.type === 'date'){
                    field['pickerOpen'] = false;
                }
                inputs.push(field);
            });
            return inputs;
        },*/
        paginationFrom: function () {
            return this.preparedFilters.Skip;
        },
        paginationTo: function () {
            let to = Number(this.preparedFilters.Skip) + Number(this.preparedFilters.Take);
            return to <= this.listCount? to: this.listCount;
        },
        paginationPrevDisabled: function () {
            return (Number(this.preparedFilters.Skip) + Number(this.preparedFilters.Take)) <= Number(this.preparedFilters.Take);
        },
        paginationNextDisabled: function () {
            return (Number(this.preparedFilters.Skip) + Number(this.preparedFilters.Take)) >= Number(this.listCount);
        },

    },
    methods:{
        loadList: async function () {
            await this.prepareFilters();
            //console.log(this.filters);
            //console.log(this.preparedFilters);
            this.loading = true;
            await this.$axios.post(this.endpoint, this.preparedFilters)
                .then((response) => {
                    this.list = response.data.List;
                    this.listCount = response.data.Count;
                    this.loading = false;

                    this.saveFiltersToLocalStorage();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                })
        },
        paginateNext: async function () {
            this.filters.Skip += this.filters.Take;
            console.log(this.filters.Skip);
            await this.loadList();
        },
        paginatePrev: async function () {
            this.filters.Skip -= this.filters.Take;
            await this.loadList();
        },
        prepareFilters: function () {
            // Create reactive preparedFilters object by copying filters
            this.preparedFilters = { ...this.filters };

            // Add additional order settings
            this.preparedFilters['OrderByDescending'] = this.preparedOrderBy.descending;

            if (this.preparedOrderBy.key) {
                this.preparedFilters['OrderBy' + this.preparedOrderBy.key] = true;
            }

            //console.log(JSON.stringify(this.filters));
            //console.log(JSON.stringify(this.preparedFilters));
        },
        changeOrderBy: async function (key) {
            this.preparedOrderBy.key = key;
            this.preparedOrderBy.descending = !this.preparedOrderBy.descending;
            await this.loadList();
        },

        getColumnValue: function(item, key) {
            if (key.includes(".")) {
                let keys = key.split(".");
                return keys.reduce((obj, k) => (obj && obj[k] !== undefined) ? obj[k] : undefined, item);
            }
            return item[key];
        },

        prepareArrayFilterValue: function (listItem, tableField) {
            let value = this.getColumnValue(listItem, tableField.key);
            let label = null;
            if(value){
                label = tableField.array.find(x => x.id === value).label;
                if(tableField.replaceLabels){
                    for(let i = 0; i < tableField.replaceLabels.length; i++){
                        if(value === tableField.replaceLabels[i].id){
                            label = tableField.replaceLabels[i].label;
                        }
                    }
                }
                if(tableField.replaceLabelsGroup){
                    for(let i = 0; i < tableField.replaceLabelsGroup.ids.length; i++){
                        if(value === tableField.replaceLabelsGroup.ids[i]){
                            label = tableField.replaceLabelsGroup.label;
                        }
                    }
                }
            }
            return label;
        },

        getFilteredArray: function (array, excludes) {
            return array.filter(x => !excludes.includes(x.id) );
        },
        saveFiltersToLocalStorage: async function () {
            await this.$globalHelpers.setLocalStorageItem(this.filtersStorageKey, JSON.stringify(this.preparedFilters));
        },
        resetLocalStorageFilters: async function () {
            await this.$globalHelpers.removeLocalStorageItem(this.filtersStorageKey);
            this.resetLocalStorageFiltersHandler();
        },
        toggleArrayFilterSelection: function(searchFilter){
            let filteredArray = this.getFilteredArray(searchFilter.array, searchFilter.excludes);
            this.$nextTick(() => {
                if (this.filters[searchFilter.filterKey].length === filteredArray.length) {
                    this.filters[searchFilter.filterKey] = []
                } else {
                    this.filters[searchFilter.filterKey] = this.$globalHelpers.pluck(filteredArray, 'id');
                }
            })
        },
        debug: function(val){
            console.log(val);
        },
    },
    async created() {
        await this.loadList();
        //console.log(this.searchFilters);
    }
}
</script>

<style scoped>

</style>