<template>
    <div style="font-size: 12px;">
        <v-row style="font-size: 12px;">
            <v-col cols="11" sm="6">
                (অনুচ্ছেদ এর ০৫(ক), এফই সার্কুলার নং ৩৯/২০১৮ দ্রষ্টব্য)
            </v-col>
            <v-col cols="11" sm="5" style="text-align: right; text-decoration: underline;">
                ফরম - 'খ'
            </v-col>
        </v-row>
        <div style="margin-top: 5px; text-align: center;">
            রপ্তানি উন্নয়ন ব্যুরো প্রদেয় প্রত্যয়ন সনদপত্র
            <div style="text-decoration: underline;">টুপি রপ্তানি বিপরীতে রপ্তানির ভর্তুকি প্রাপ্তির সনদপত্র।</div>
        </div>
        <v-row style="margin-top: 3px;">
            <v-col cols="11" sm="4">
                ১। আবেদনকারী নাম, ইআরসি নম্বর ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.User.OrganizationName }} <span style="margin-left: 10px;">ইআরসি নম্বরঃ {{ formData.SvForm.ERCNo }}</span>
                <div>{{ formData.SvForm.OfficeAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ২। রপ্তানি ঋণপত্র/চুক্তিপত্র নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.LcNo }}
                <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.LcDate, '', 'DD/MM/YYYY') }}
                <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.LcCurrencyId) }} {{ formData.SvForm.LcValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৩। বিদেশি ক্রেতার নাম ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7" v-if="formData.SvForm.Importer">
                {{formData.SvForm.Importer.Name}}
                <div>{{formData.SvForm.DestinationAddress}}, {{formData.SvForm.DestinationCity}}, {{formData.SvForm.DestinationZipCode}}, {{ $globalHelpers.getCountryNameById(formData.SvForm.DestinationCountryId)}}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৪। বিদেশি ক্রেতার ব্যাংকের নাম ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.ImporterBankName }}
                <div>{{ formData.ImporterBankAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৫। ক) ইনভয়েস নম্বরঃ
                <div style="margin-left: 10px;">খ) ইনভয়েসে উল্লেখিত পণ্যের পরিমাণঃ</div>
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.InvoiceNo }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.InvoiceDate, '', 'DD/MM/YYYY') }}
                <div>
                    <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                    <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.InvoiceCurrencyId) }} {{ formData.SvForm.InvoiceValue }}</div>
            </v-col>
        </v-row>
        <div style="margin-top: 5px;">
            ৬। রপ্তানিকৃত টুপি উৎপাদনে স্থানীয় উপকরণাদির সংগ্রহসূত্র (সরবরাহকারীর নাম ও ঠিকানা):
            <v-simple-table dense style="margin-left: 15px;">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th style="text-align: left;">সরবরাহকারীর নাম ও ঠিকানা</th>
                        <th style="text-align: left;">পরিমাণ</th>
                        <th style="text-align: right;">মূল্য</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(material, i) in formData.IncentiveFormLocalMaterials" :key="i">
                        <td>{{ material.SupplierName }}, {{ material.SupplierAddress }}</td>
                        <td>{{ material.MAterial }}, {{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                        <td style="text-align: right;">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div style="margin-top: 5px;">
            ৭। রপ্তানিকৃত টুপি উৎপাদনে ব্যবহৃত আমদানিকৃত উপকরণাদির সংগ্রহসূত্র (সরবরাহকারীর নাম ও ঠিকানা):
            <v-simple-table dense v-if="formData.IncentiveFormImportedMaterials.length > 0" style="margin-left: 15px;">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th style="text-align: left;">সরবরাহকারীর নাম ও ঠিকানা</th>
                        <th style="text-align: left;">পরিমাণ</th>
                        <th style="text-align: right;">মূল্য</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(material, i) in formData.IncentiveFormImportedMaterials" :key="i">
                        <td>{{ material.SupplierName }}, {{ material.SupplierAddress }}</td>
                        <td>{{ material.MAterial }}, {{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                        <td style="text-align: right;">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div style="margin-top: 5px;">
            <div v-if="formData.IncentiveTypeId !== 3">
                ৮। রপ্তানি পণ্যের বিবরণঃ
                <v-simple-table dense style="margin-left: 15px;">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th style="text-align: left;">পণ্যের বিবরণ</th>
                            <th style="text-align: left;">পরিমাণ</th>
                            <th style="text-align: right;">মূল্য</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(hsCode, i) in formData.SvForm.SvFormHSCodes" :key="i">
                            <td>{{ hsCode.HSCode.Code }}: {{ hsCode.HSCode.Description}}</td>
                            <td>{{ hsCode.Qty }} {{ $globalHelpers.getUnitTypName(hsCode.UnitTypeId) }}</td>
                            <td style="text-align: right;">{{ $globalHelpers.getCurrencyName(hsCode.CurrencyId) }} {{ hsCode.Price }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৯। জাহাজীকরণের তারিখঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedDate(formData.SvForm.BLDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>গন্তব্য বন্দর {{ formData.SvForm.DestinationPort }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১০। ইএক্সপি নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedEXPNo(formData.SvForm.EXPNo)  }} <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.ExpCurrencyId) }} {{ formData.SvForm.ExpValue }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.EXPDate, '', 'DD/MM/YYYY') }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১১। মোট প্রত্যাবাসিত রপ্তানি মূল্য (বৈদেশিক মূদ্রায়):
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getCurrencyName(formData.RepatriatedValueCurrencyId) }} {{ formData.RepatriatedValue }} <span style="margin-left: 10px;">নীট এফওবি মূল্য (বৈদেশিক মূদ্রায়): {{ $globalHelpers.getCurrencyName(formData.RealisedNetFOBValueCurrencyId) }} {{ formData.RealisedNetFOBValue }}</span>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১২। প্রত্যাবাসিত রপ্তানি মূল্যের সনদপত্র নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.PRCId }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.PRCDate, '', 'DD/MM/YYYY') }}
            </v-col>
        </v-row>
        <div class="incentive-footer-declaration">
            <div style="margin-top: 20px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                এতদ্বারা প্রত্যয়ন করা যাচ্ছে যে, আমাদের নিজস্ব কারখানায় উৎপাদিত টুপি উপরোক্ত ৬ নং ও ৭ নং ক্রমিকে বর্ণিত সূত্র হতে উপকরণাদি সংগ্রহের মাধ্যমে রপ্তানির বিপরীতে রপ্তানি ভর্তুকির জন্য উপরোক্ত অনুচ্ছেদগুলোতে উল্লিখিত বক্তব্য সঠিক ও নির্ভুল। বিদেশী ক্রেতা/আমদানিকারকের ক্রয়াদেশের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হলো।
            </div>
            <div style="margin-top: 50px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                রপ্তানিকারকের উপরোক্ত ঘোষণার যথার্থতা যাচাইয়ান্তে সঠিক পাওয়া গিয়েছে। ৮ নং ক্রমিকে উল্লিখিত ঘোষিত রপ্তানিমূল্য যৌক্তিক ও বিদ্যমান আন্তর্জাতিক বাজার মূল্যের সংগে সংগতিপূর্ণ পাওয়া গিয়েছে এবং বিদেশী ক্রেতার যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত হওয়া গিয়েছে। প্রত্যাবাসিত রপ্তানি মূলোর (নীট এফওবি মূল্য) ওপর রপ্তানি ভর্তুকি পরিশোধের সুপারিশ করা হলো।
            </div>
            <div style="margin-top: 40px; text-align: right;">রপ্তানি উন্নয়ন ব্যুরোর দুইজন উপযুক্ত কর্মকর্তার স্বাক্ষর, তারিখ ও সীল</div>
        </div>

        <div class="incentive-footer-warning">
            [কোন প্রকার ঘষামাজা, কাটাছেড়া বা সংশোধন করা হলে এই প্রত্যয়নপত্র বাতিল বলে গণ্য হবে।]
        </div>
    </div>
</template>

<script>
export default {
    name: "IncentiveForm7",
    data: function() {
        return {}
    },
    props: {
        formData: {
            type: Object,
            default: ()=> null
        },
        materialTotals: {
            type: Object,
            default: ()=> null
        },
    },
    methods: {

    },
}
</script>

<style scoped>
    table tr td, table tr th{
        padding: 0 !important;
        margin: 0 !important;
        height: 5px !important;
        font-size: 12px !important;
    }
    .incentive-pdf-root{
        font-size: 12px;
    }
</style>