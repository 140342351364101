<template>
    <div>
        <v-row style="font-size: 12px;">
            <v-col cols="11" sm="6">
                (সার্কুলার নং ০৯/২০০৬ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)
            </v-col>
            <v-col cols="11" sm="5" style="text-align: right; text-decoration: underline;">
                ফরম - ঘ
            </v-col>
        </v-row>
        <div style="margin-top: 5px; text-align: center; text-decoration: underline;">
            রপ্তানি উন্নয়ন ব্যুরো/সংশ্লিষ্ট এ্যাসোসিয়েশন কর্তৃক প্রদেয় সনদপত্র<br />
            হালাল মাংস রপ্তানি খাতে রপ্তানিক ভর্তুকী প্রাপ্তির সনদ পত্র।
        </div>
        <v-row style="margin-top: 3px;">
            <v-col cols="11" sm="4">
                ১। আবেদনকারী প্রতিষ্ঠানের নাম ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.User.OrganizationName }}
                <div>{{ formData.SvForm.OfficeAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ২। রপ্তানি ঋণ পত্রের নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.LcNo }}
                <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.LcDate, '', 'DD/MM/YYYY') }}
                <span style="margin-left: 5px;"></span>মূল্যমানঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.LcCurrencyId) }} {{ formData.SvForm.LcValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৩। বিদেশি ক্রেতার নাম ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7" v-if="formData.SvForm.Importer">
                {{formData.SvForm.Importer.Name}}
                <div>{{formData.SvForm.DestinationAddress}}, {{formData.SvForm.DestinationCity}}, {{formData.SvForm.DestinationZipCode}}, {{ $globalHelpers.getCountryNameById(formData.SvForm.DestinationCountryId)}}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৪। বিদেশি ক্রেতার ব্যাংকের নাম ও ঠিকানাঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.ImporterBankName }}
                <div>{{ formData.ImporterBankAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৫। ক) ইনভয়েস নম্বরঃ
                <div style="margin-left: 10px;">খ) ইনভয়েসে উল্লেখিত পণ্যের পরিমাণঃ</div>
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.InvoiceNo }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.InvoiceDate, '', 'DD/MM/YYYY') }}
                <div>
                    <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                    <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.InvoiceCurrencyId) }} {{ formData.SvForm.InvoiceValue }}</div>
            </v-col>
        </v-row>
        <div style="margin-top: 5px;">
            <div v-if="formData.IncentiveTypeId !== 3">
                ৬। ক) রপ্তানি পণ্যের বিবরণঃ
                <v-simple-table dense style="margin-left: 15px;">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th style="text-align: left;">পণ্যের বিবরণ</th>
                            <th style="text-align: left;">পরিমাণ</th>
                            <th style="text-align: right;">মূল্য</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(hsCode, i) in formData.SvForm.SvFormHSCodes" :key="i">
                            <td>{{ hsCode.HSCode.Code }}: {{ hsCode.HSCode.Description}}</td>
                            <td>{{ hsCode.Qty }} {{ $globalHelpers.getUnitTypName(hsCode.UnitTypeId) }}</td>
                            <td style="text-align: right;">{{ $globalHelpers.getCurrencyName(hsCode.CurrencyId) }} {{ hsCode.Price }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>

            <v-row style="margin-top: 1px;">
                <v-col cols="11" sm="4">
                    <div style="margin-left: 10px;">
                        খ )প্রকৃত রপ্তানিকৃত পণ্যের পরিমাণঃ
                    </div>
                </v-col>
                <v-col cols="11" sm="7">
                    <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                    মূল্যঃ <span v-for="(price, i) in materialTotals.hscode.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                </v-col>
            </v-row>
        </div>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৭। জাহাজীকরণের তারিখঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedDate(formData.SvForm.BLDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>গন্তব্য বন্দর {{ formData.SvForm.DestinationPort }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৮। ইএক্সপি নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedEXPNo(formData.SvForm.EXPNo)  }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.EXPDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.ExpCurrencyId) }} {{ formData.SvForm.ExpValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৯। মোট প্রত্যাবাসিত রপ্তানি মূল্য (বৈদেশিক মূদ্রায়):
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getCurrencyName(formData.RepatriatedValueCurrencyId) }} {{ formData.RepatriatedValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১০। প্রত্যাবাসিত রপ্তানি মূল্যের সনদপত্র নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.PRCId }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.PRCDate, '', 'DD/MM/YYYY') }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১১। নীট এফওবি মূল্যঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getCurrencyName(formData.RealisedNetFOBValueCurrencyId) }} {{ formData.RealisedNetFOBValue }}
            </v-col>
        </v-row>
        <div class="incentive-footer-declaration">
            <div style="margin-top: 20px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                এতদ্বারা প্রত্যয়ন করা যাচ্ছে যে, আমাদের নিজস্ব খামারে উৎপাদিত হালাল প্রক্রিয়ায় জবাইকৃত মাংস রপ্তানির বিপরীতে রপ্তানি ভর্তুকির জন্য উপরোক্ত অনুচ্ছেদসমূহের বক্তব্য সঠিক ও নিভুল। বিদেশী ক্রেতা/আমদানিকারকের ক্রয়াদেশের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হইল।
            </div>
            <div style="margin-top: 50px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                রপ্তানিকারকের উপরোক্ত ঘোষণাগুলির যথার্থতা যাচাইয়ান্তে সঠিক পাওয়া গিয়েছে, ঘোষিত রপ্তানিমূল্য যৌক্তিক ও বিদ্যমান আন্তর্জাতিক বাজার মূল্যের সংগে সংগতিপূর্ণ পাওয়া গিয়েছে। প্রত্যাবাসিত মূলোর অনুকূলে রপ্তানিকারককে রপ্তানি ভর্তুকি প্রদানের জন্য সুপারিশ করা হইল।
            </div>
            <div style="margin-top: 40px; text-align: right;">রপ্তানি উন্নয়ন ব্যুরো/সংশ্লিষ্ট এ্যাসোসিয়েশন এর দুইজন কর্মকর্তার স্বাক্ষর ও সীল</div>
        </div>

        <div class="incentive-footer-warning incentive-footer-warning-boxed">
            কোন প্রকার ঘষামাজা, কাটাছেঁড়া বা সংশোধন করা হলে এই প্রত্যয়নপত্র বাতিল বলে গণ্য হবে।
        </div>
    </div>
</template>

<script>
export default {
    name: "IncentiveForm12",
    data: function() {
        return {}
    },
    props: {
        formData: {
            type: Object,
            default: ()=> null
        },
        materialTotals: {
            type: Object,
            default: ()=> null
        },
    },
    methods: {

    },
}
</script>

<style scoped>
    table tr td, table tr th{
        padding: 0 !important;
        margin: 0 !important;
        height: 5px !important;
        font-size: 12px !important;
    }
    .incentive-pdf-root{
        font-size: 12px;
    }
</style>